import { lazy } from 'react';
import {
  ApplicationShell,
  createApolloClient,
  selectProjectKeyFromUrl,
  setupGlobalErrorListener,
} from '@commercetools-frontend/application-shell';
import type { ApplicationWindow } from '@commercetools-frontend/constants';
import loadMessages from '../../load-messages';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { Sdk } from '@commercetools-frontend/sdk';
import * as globalActions from '@commercetools-frontend/actions-global';
import { ActionError } from '@commercetools-frontend/actions-global/dist/declarations/src/actions/handle-action-error';

declare let window: ApplicationWindow;

// Here we split up the main (app) bundle with the actual application business logic.
// Splitting by route is usually recommended and you can potentially have a splitting
// point for each route. More info at https://reactjs.org/docs/code-splitting.html
const AsyncApplicationRoutes = lazy(
  () => import('../../routes' /* webpackChunkName: "routes" */)
);

// Ensure to setup the global error listener before any React component renders
// in order to catch possible errors on rendering/mounting.
setupGlobalErrorListener();

const { mcApiUrl } = window.app;
const projectKey = selectProjectKeyFromUrl();

let headers: RestLink.InitializationHeaders | undefined = {
  Accept: 'application/json',
};

if (window.app.env === 'development')
  headers.Authorization = `Bearer ${window.sessionStorage.getItem(
    'sessionToken'
  )}`;

const restLink = new RestLink({
  uri: `${mcApiUrl}/proxy/ctp/${projectKey}`,
  headers: headers,
  credentials: 'include',
});

const apolloClient = createApolloClient({
  cache: {
    // Your custom configuration, according to the Apollo cache documentation.
    // https://www.apollographql.com/docs/react/caching/cache-configuration/
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([restLink, apolloClient.link]),
  cache: new InMemoryCache(),
});

const EntryPoint = () => (
  <ApplicationShell
    enableReactStrictMode
    environment={window.app}
    applicationMessages={loadMessages}
    apolloClient={client}
    onRegisterErrorListeners={({ dispatch }) => {
      Sdk.Get.errorHandler = (error: ActionError) =>
        globalActions.handleActionError(error)(dispatch);
    }}
  >
    <AsyncApplicationRoutes />
  </ApplicationShell>
);
EntryPoint.displayName = 'EntryPoint';

export default EntryPoint;
